@-moz-keyframes header {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes header {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-ms-keyframes header {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes header {
  0% {
    -moz-transform: translate3d(0, 1em, 0);
    -webkit-transform: translate3d(0, 1em, 0);
    -ms-transform: translate3d(0, 1em, 0);
    transform: translate3d(0, 1em, 0);
    opacity: 0;
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.header {
  -moz-animation: header 1s 0.5s forwards;
  -webkit-animation: header 1s 0.5s forwards;
  -ms-animation: header 1s 0.5s forwards;
  animation: header 1s 0.5s forwards;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  opacity: 0;
  position: relative;
  top: -1em;
  width: 100%;
  /* height: 90%; */
  flex: 1 1;
}

.headerImg {
  width: 220px;
  height: 64px;
}

.headerNav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  font-size: 13px;
  opacity: 0.75;
  padding: 0 10px;
}
