.footer {
  width: 100%;
  /* position: absolute; */
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 9; */
}

.footer a {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer img {
  width: 100%;
  height: 100%;
}

.footer img:hover {
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .footer a {
    /* width: 180px; */
  }
}
