.navHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* column-gap: 20px; */
  border: 1px solid #afafaf;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 6px 0 10px 0;
  font-size: 13px;
  transition: width 0.5s, font-size 0.5s ease;
  padding-left: 25%;
  padding-right: 25%;
  background-color: #ffffff;
}

@media screen and (max-width: 736px) {
  .navHeader {
    padding-left: 18%;
    padding-right: 18%;
  }
}

@media screen and (min-width: 736px) {
  .navHeader {
    width: 100%;
    font-size: 16px;
    /* column-gap: 3%; */
  }

  .navHeader a {
    width: 100px;
    text-align: center;
  }
}
