.zoomModal {
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  row-gap: 10px;
  touch-action: none;
}

.zoomMenu {
  z-index: 101;
  text-align: right;
}

.zoomMenu img {
  width: 25px;
  cursor: pointer;
}

.zoomImg {
  width: 100%;
  height: 100%;
}

.zoomAnimated {
  position: relative;
  width: 100%;
  height: 100%;
}
