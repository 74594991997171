.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  touch-action: pan-y pinch-zoom;
}

.about p {
  font-size: 12px;
  line-height: 15px;
  transition: font-size 0.5s ease;
}

.aboutBody {
  width: 100%;
  padding: 20px 35px 40px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  flex: 1;
}

.contactProfileWrapper {
  height: 350px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.contactProfileWrapper img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 2px;
}

.introduce {
  max-width: 320px;
  text-align: center;
  line-height: 16px;
}

.aboutChapterTitle {
  font-size: 16px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 5px;
}

.historyWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  row-gap: 3px;
  margin-bottom: 30px;
}

.history {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  column-gap: 10px;
}

.historyTitle {
  min-width: 45px;
}

@media screen and (max-height: 700px) {
  .introduce {
    margin-bottom: 0px;
  }
  .aboutChapterTitle {
    margin-top: 20px;
  }
}

@media screen and (min-width: 736px) {
  .introduce {
    max-width: 550px;
    line-height: 18px;
  }
  .aboutChapterTitle {
    font-size: 20px;
  }

  .about p {
    font-size: 15px;
    line-height: 22px;
  }

  .history {
    column-gap: 30px;
  }
  .historyTitle {
    min-width: 60px;
  }
}
