.gallery {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.galleryGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 9px;
  transition: width 0.5s ease;
  padding: 20px 35px 20px 35px;
}

.galleryImgWrapper {
  position: relative;
  aspect-ratio: 1/1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.galleryImgWrapper::before {
  content: ""; /* 가상 요소 생성 */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* 기본적으로 투명한 검은색 */
}

.galleryImgWrapper:hover::before {
  background-color: rgba(0, 0, 0, 0.2); /* 호버 시 반투명한 검은색 */
}

.galleryZoomImg {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 736px) {
  .galleryLogoImg img {
    width: 172px;
    height: 50px;
  }
  .galleryGrid {
    width: 70%;
  }
  .galleryZoomMenu img:hover {
    filter: opacity(0.5);
  }
}
