@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,900");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Apple SD Gothic Neo", sans-serif;
  letter-spacing: -0.025em;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 100;
  src: url(./assets/AppleSDGothicNeoT.woff2) format("font-woff2");
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 200;
  src: url(./assets/AppleSDGothicNeoUL.woff2) format("font-woff2");
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 300;
  src: url(./assets/AppleSDGothicNeoL.woff2) format("font-woff2");
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 400;
  src: url(./assets/AppleSDGothicNeoR.woff2) format("font-woff2");
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 500;
  src: url(./assets/AppleSDGothicNeoM.woff2) format("font-woff2");
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 600;
  src: url(./assets/AppleSDGothicNeoSB.woff2) format("font-woff2");
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 700;
  src: url(./assets/AppleSDGothicNeoB.woff2) format("font-woff2");
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 800;
  src: url(./assets/AppleSDGothicNeoEB.woff2) format("font-woff2");
}

@font-face {
  font-family: "Apple SD Gothic Neo";
  font-weight: 900;
  src: url(./assets/AppleSDGothicNeoH.woff2) format("font-woff2");
}
