a {
  letter-spacing: 0px;
  text-decoration: none;
  color: #000;
}

a:visited {
  color: #000;
}

a:hover {
  font-weight: 900;
  color: #4e00e9;
}
