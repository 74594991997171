.imageCard {
  width: 100%;
  height: 100%;
}

.imageCardContents {
  width: 100%;
  /* height: 100%; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  align-items: start;
  background-color: #fff;
  padding: 20px 0px 20px 0px;
  overflow: hidden;
}

.imageCardImgWrapper {
  /* height: 70%; */
  height: 250px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex: 1;
}

.imageCardImgWrapper div {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  background-size: contain;
  background-origin: content-box; /*패딩을 제외한 구역만 백그라운드 지정*/
  background-repeat: no-repeat;
  background-position: center;
}

.imageCardTitle {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
}

.imageCardDescription {
  width: 100%;
}

.imageCardLeftArrows,
.imageCardRightArrows {
  position: absolute;
  top: 0;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.imageCardLeftArrows {
  padding-left: 10px;
  padding-right: 30px;
}
.imageCardRightArrows {
  padding-right: 10px;
  padding-left: 30px;
}

.imageCardLeftArrows img,
.imageCardRightArrows img {
  width: 7px;
  height: 12px;
  opacity: 0;
  animation: fadeOut 2s ease;
}

.activateFadeOut img {
  opacity: 1;
  animation: fadeIn 0.1s ease-out;
}

.imageCardLeftArrows {
  left: 0px;
}

.imageCardRightArrows {
  right: 0px;
}

.imageCard h6 {
  text-align: center;
}

.imageCard p {
  text-align: center;
  font-size: 9px;
  font-weight: 300;
  line-height: 13px;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (min-width: 736px) {
  .imageCardContents {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }

  .imageCardLeftArrows img,
  .imageCardRightArrows img {
    width: 12px;
    height: 20.4px;
    filter: invert();
  }

  .imageCardLeftArrows:hover img,
  .imageCardRightArrows:hover img {
    opacity: 1;
  }

  .imageCardImgWrapper {
    height: 80%;
  }

  .imageCard h6 {
    font-size: 13px;
  }

  .imageCard p {
    font-size: 12px;
  }
}
