.bg {
  -moz-animation: bg 210s linear infinite;
  -webkit-animation: bg 210s linear infinite;
  -ms-animation: bg 210s linear infinite;
  animation: bg 210s linear infinite;

  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  background: #ffffff url("./heehee.art.png") bottom left;
  background-repeat: repeat-x;
  /* height: 100%; */
  left: 0;
  opacity: 1;
  position: fixed;
  z-index: -1;
}

@-moz-keyframes bg {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  50% {
    -moz-transform: translate3d(-2250px, 0, 0);
    -webkit-transform: translate3d(-2250px, 0, 0);
    -ms-transform: translate3d(-2250px, 0, 0);
    transform: translate3d(-2250px, 0, 0);
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes bg {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  50% {
    -moz-transform: translate3d(-2250px, 0, 0);
    -webkit-transform: translate3d(-2250px, 0, 0);
    -ms-transform: translate3d(-2250px, 0, 0);
    transform: translate3d(-2250px, 0, 0);
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-ms-keyframes bg {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  50% {
    -moz-transform: translate3d(-2250px, 0, 0);
    -webkit-transform: translate3d(-2250px, 0, 0);
    -ms-transform: translate3d(-2250px, 0, 0);
    transform: translate3d(-2250px, 0, 0);
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bg {
  0% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  50% {
    -moz-transform: translate3d(-2250px, 0, 0);
    -webkit-transform: translate3d(-2250px, 0, 0);
    -ms-transform: translate3d(-2250px, 0, 0);
    transform: translate3d(-2250px, 0, 0);
  }

  100% {
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bg {
  /* background-size: 2000px auto; */
  background-size: auto 40%;
  width: 6750px;
}

@media screen and (max-width: 1280px) {
  /* BG */

  @-moz-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-750px, 0, 0);
      -webkit-transform: translate3d(-750px, 0, 0);
      -ms-transform: translate3d(-750px, 0, 0);
      transform: translate3d(-750px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-webkit-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-750px, 0, 0);
      -webkit-transform: translate3d(-750px, 0, 0);
      -ms-transform: translate3d(-750px, 0, 0);
      transform: translate3d(-750px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-ms-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-750px, 0, 0);
      -webkit-transform: translate3d(-750px, 0, 0);
      -ms-transform: translate3d(-750px, 0, 0);
      transform: translate3d(-750px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-750px, 0, 0);
      -webkit-transform: translate3d(-750px, 0, 0);
      -ms-transform: translate3d(-750px, 0, 0);
      transform: translate3d(-750px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .bg {
    /* background-size: 2000px auto; */
    /* background-size: auto 40%; */
    width: 2250px;
    -moz-animation: bg 180s linear infinite;
    -webkit-animation: bg 180s linear infinite;
    -ms-animation: bg 180s linear infinite;
    animation: bg 180s linear infinite;
  }
}

/* Mobile */

@media screen and (max-width: 736px) {
  /* BG */

  @-moz-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-300px, 0, 0);
      -webkit-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-webkit-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-300px, 0, 0);
      -webkit-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-ms-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-300px, 0, 0);
      -webkit-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-300px, 0, 0);
      -webkit-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .bg {
    /* background-size: 2000px auto; */
    width: 2250px;
    -moz-animation: bg 150s linear infinite;
    -webkit-animation: bg 150s linear infinite;
    -ms-animation: bg 150s linear infinite;
    animation: bg 150s linear infinite;
  }
}

/* Mobile (Portrait) */

@media screen and (max-width: 480px) {
  /* BG */

  @-moz-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-412.5px, 0, 0);
      -webkit-transform: translate3d(-412.5px, 0, 0);
      -ms-transform: translate3d(-412.5px, 0, 0);
      transform: translate3d(-412.5px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-webkit-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-412.5px, 0, 0);
      -webkit-transform: translate3d(-412.5px, 0, 0);
      -ms-transform: translate3d(-412.5px, 0, 0);
      transform: translate3d(-412.5px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-ms-keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-412.5px, 0, 0);
      -webkit-transform: translate3d(-412.5px, 0, 0);
      -ms-transform: translate3d(-412.5px, 0, 0);
      transform: translate3d(-412.5px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes bg {
    0% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    50% {
      -moz-transform: translate3d(-412.5px, 0, 0);
      -webkit-transform: translate3d(-412.5px, 0, 0);
      -ms-transform: translate3d(-412.5px, 0, 0);
      transform: translate3d(-412.5px, 0, 0);
    }

    100% {
      -moz-transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .bg {
    background: #ffffff url("./heehee.art.png") bottom left;
    background-repeat: repeat-x;
    background-size: auto 40%;
    width: 1237.5px;
    -moz-animation: bg 60s linear infinite;
    -webkit-animation: bg 60s linear infinite;
    -ms-animation: bg 60s linear infinite;
    animation: bg 60s linear infinite;
  }
}
