.mainLogoImg {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  background-color: #ffffff;
}

.mainLogoImg img {
  width: auto;
  height: 40px;
  transition: width 0.5s ease;
}

@media screen and (max-height: 700px) {
  .mainLogoImg {
    height: 70px;
  }
  .mainLogoImg img {
    height: 30px;
  }
}
