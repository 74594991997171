.contact {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  touch-action: pan-y;
  padding-top: 20px;
}

.contactBody {
  width: 100%;
  height: 70%;
  padding: 0 35px 0px 35px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactBody h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

.contactInfoWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
}

@media screen and (min-width: 736px) {
  .contactBody h4 {
    font-size: 20px;
  }
}
