.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalBg {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 101;
  width: 100%;
  height: 100%;
}

.modalBody {
  position: absolute;
  z-index: 102;
  width: 98%;
  top: 20%;
  max-height: 85%;
}

.modalMenu {
  position: fixed;
  z-index: 103;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.modalMenu img {
  cursor: pointer;
  width: 25px;
}

@media screen and (min-width: 736px) {
  .modalBody {
    width: 700px;
    height: 100%;
    position: static;
    max-height: 100%;
  }

  .modalMenu img:hover {
    filter: opacity(0.5);
  }
}
